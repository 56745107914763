import React, { useEffect } from "react"

import Image from "gatsby-image"
import { Link, navigate } from "gatsby"
import dayjs from "dayjs"

export const BlogCard = ({ post }) => {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "blog/" + post.url)
  },[]);

  const handleClick = (e, slug) => {
    e.preventDefault()
    navigate(`/blog/${slug}`)
  }


  return (
    <>
            <article
            className="w-full h-auto py-12 border-b border-gray-300 outline-none focus:outline-none md:px-8 md:w-1/2 lg:px-0 lg:flex lg:items-center lg:justify-between"
            onClick={e => { handleClick(e, post.url); addWebEvent("visit", "blog/" + post.url) } }
      
            >
            <div className="w-full md:h-64 lg:h-56">
            <img className="w-full md:h-64 lg:h-56 object-cover" src={post.image} alt="Modal blog thumbnail"></img>
            </div>
            <div className="w-full h-auto flex flex-col justify-center mt-6 lg:mt-0 lg:p-6">
              <span className="text-sm text-gray-700">
                  {dayjs(post.created_at).format("MMMM DD, YYYY")}
              </span>
              <h5 className="mt-4">{post.title}</h5>
              
              <Link
                to={`/blog/${post.url}`}
                className="mt-6 italic font-bradford text-gray-500 underline cursor-pointer"
                
              >
                Read Article
                
              </Link>


            </div>
            </article>
    </>
  )
}
