import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer"
import "../css/tailwind.css"
import "../css/global.css"
import SEO from "./seo"

const Layout = ({ children, animateHeader = false }) => {

  return (
    <>
      <Header animate={animateHeader} />
      <main className="relative" >{children}</main>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
