import React, { useEffect, useState } from "react"
import Layout3 from "../components/layout3"
//import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"
//import { useBannerContext } from "../context"
//import "../../../templates/post.css"
import { BlogCard } from "../components/blog/blog-card"
//import ArrowSubmit from "../components/images/arrow-submit"
//var dayjs = require('dayjs')
import "./blog_style.css"

export default () => {

  //var post_content = useState();
  //const response_obj = [];
  //const post_content = null;

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "blog")
  },[]);

  //HUB-SPOT TAG
  //useEffect(() => {
  //  const script = document.createElement('script');
  //  script.src="//js-na1.hs-scripts.com/23564468.js";
  //  script.id="hs-script-loader";
  //  script.async = true;
  //  document.body.appendChild(script);
  //},[]);

  //API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
  function httpRequest(address, reqType, asyncProc) {
      //var req = new XMLHttpRequest();
      var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
      if (asyncProc) { 
        req.onreadystatechange = function() { 
          if (this.readyState == 4) {
            asyncProc(this);
          } 
        };
      }
      //req.open(reqType, address, !(!asyncProc));
      typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
      //req.send();
      typeof window !== "undefined" ? req.send() : ""
    return req;
    }

    var req = httpRequest("https://api.checksolarcost.com/v1/getArticles", "POST");  // In this example you don't want to GET the full page contents
    const response = req.responseText;
    //const response_obj = JSON.parse(response);
    const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
    //const post_content = response_obj.map(post => {return (<BlogCard post={post}/>)}) 
    const post_content = typeof window !== "undefined" ? response_obj.map(post => {return (<BlogCard post={post}/>)}) : ""

  return (
    <>
      <SEO title="Blog" />
      <Layout3>
        <h1 className="hidden">H1</h1>
        <div className="h-5" ></div>
      
          <iframe src="https://blog.checksolarcost.com/" title="description" frameBorder="0" className="iframe"></iframe>
          
      </Layout3>
    </>
  )
}